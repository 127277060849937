<template>
  <div>
    <KTPortlet v-bind:title="'Best Sellers'">
      <template v-slot:title>
        {{ $t("teacher.timeTable") }}
      </template>
      <template v-slot:toolbar>
        <b-form-datepicker id="example-datepicker" v-model="date" class="mb-2" @context="reloadData"
                           :date-disabled-fn="dateDisabled"
                           start-weekday="1"
                           locale="ru"></b-form-datepicker>
      </template>
      <template v-slot:body>
        <v-row class="hide-on-mobile">
          <v-col>
            <v-sheet height="600">
              <v-calendar
                  ref="calendar"
                  :value="date"
                  :events="events"
                  :type="type"
                  color="primary"
                  @click:event="showEvent"
                  @click:date="viewDay"
                  locale="ru"
                  weekdays="1, 2, 3, 4, 5, 6"
                  first-interval="7"
                  interval-count="15"
              ></v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
        
        <div class="row show-on-mobile">
          <v-col>
            <v-sheet height="600">
              <v-calendar
                  ref="calendar"
                  :value="date"
                  :events="events"
                  :type="type2"
                  color="primary"
                  @click:event="showEvent"
                  @click:date="viewDay"
                  locale="ru"
                  weekdays="1, 2, 3, 4, 5, 6"
                  first-interval="7"
                  interval-count="15"
              ></v-calendar>
            </v-sheet>
          </v-col>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<style>
.nav-tabs {
  display: none;
}

/*table, th, td {
  border: 1px solid black;
}*/

.v-event-timed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.v-event-timed.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0;
}

.show-on-mobile{
  display: none;
}

.hide-on-mobile{
  display: block;
}

@media (max-width: 767px) {
  .show-on-mobile{
    display: block;
  }

  .hide-on-mobile{
    display: none;
  }
}

</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "teacherDashboard",
  components: {
    KTPortlet
  },
  data() {
    return {
      date: '',
      tabIndex: 0,
      days: {},
      daysSecondShift: {},
      lessons: {},
      lessonsSecondShift: {},
      lessonNumber: [1, 2, 3, 4, 5, 6, 7],
      currentLesson: false,
      schools: null,
      dialog: true,
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: ""
      },
      show: true,
      /*calendar*/
      today: '2019-01-08',
      type: 'week',
      type2: 'day',
      events: [],
    };
  },
  mounted() {
    //this.reloadData();
    this.$refs.calendar.scrollToTime('08:00');
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    onReset() {
    },
    reloadData() {
      let $this = this;
      let date = this.date;
      if (date == false) {
        date = new Date();
        this.date = date;
      }

      ApiService.querySecured("events/getForTeacher", {
        params: {date: date}
      })
          .then(({data}) => {
            $this.events = data.events;
            
            console.log("asas");
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    changeDate() {
      this.reloadData()
    },
    showEvent(event) {
      /*this.$router.push({name: "lessonMarks", params: {lessonId: event.event.lessonId}});*/
      this.$router.push({name: "marks", params: {id: event.event.groupSubjectId}});
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    }
  }
};
</script>
